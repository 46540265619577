import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
   {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'STOCK',
    lpAddresses: {
      22324: '0xfdA0F4361258dFD4Cc7f936285874605e46a490D',
      42355: '0xC860737DFda44e9E967664EA8319cad8780324E7',
    },
    token: serializedTokens.syrup,
    // token: serializedTokens.stock,
    quoteToken: serializedTokens.wgoldx,
  },
  {
    pid: 2,
    // v1pid: 1,
    lpSymbol: 'STOCK-GOLDX LP',
    lpAddresses: {
      22324: '0x04a87d67A487C38089736C738aEBc13D6B981c76',
      42355: '0xeE1fC47605b7c1C2bCF358c57B26Cf1F4A7c24E3',
    },
    token: serializedTokens.stock,
    quoteToken: serializedTokens.wgoldx,
  },
  {
    pid: 3,
    // v1pid: 2,
    lpSymbol: 'USDX-GOLDX LP',
    lpAddresses: {
      22324: '0xc8EdeC730CC6aBb57c213c840633071164357357',
      42355: '0x064BE6cfCcC38dE4253AD5364B9C2e5B7b8F6437',
    },
    token: serializedTokens.usdx,
    quoteToken: serializedTokens.wgoldx,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
  // {
  //   pid: 4,
  //   // v1pid: 3,
  //   lpSymbol: 'XUSDT-GOLDX LP',
  //   lpAddresses: {
  //     22324: '0x0DC8b02c393B3FD37dA5B3944Ad9d301377C5849',
  //     42355: '0x8a981Ca523C0455a695Cbb7eab8A8c070b3f87c3',
  //   },
  //   token: serializedTokens.xusdt,
  //   quoteToken: serializedTokens.wgoldx,
  // },
  {
    pid: 5,
    lpSymbol: 'HBSC-GOLDX LP',
    lpAddresses: {
      22324: '0x7950F3e88aF8De6BFf55C7404e739b36566e4c6a',
      42355: '0x7950F3e88aF8De6BFf55C7404e739b36566e4c6a',
    },
    token: serializedTokens.hbsc,
    quoteToken: serializedTokens.wgoldx,
  },
  {
    pid: 6,
    lpSymbol: 'USDT-USDX LP',
    lpAddresses: {
      22324: '0x347113579A04C527d3aB2234B0b89fDD5b3217C4',
      42355: '0x347113579A04C527d3aB2234B0b89fDD5b3217C4',
    },
    token: serializedTokens.usdt,
    quoteToken: serializedTokens.usdx,
  },
  // {
  //   pid: 5,
  //   lpSymbol: 'ETH-GOLDX LP',
  //   lpAddresses: {
  //     22324: '',
  //     42355: '0xD30916af2D1E8F5da477057A95958e724528bcCa',
  //   },
  //   token: serializedTokens.eth,
  //   quoteToken: serializedTokens.wgoldx,
  // },



].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms